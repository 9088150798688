<template>
    <div class="apply-guarante">
        <div class="apply-content">
            <div class="content-img">
                <div class="title">保函服务</div>
                <div class="text">提供专业高效一站式</div>
                <div class="text">保函办理服务</div>
            </div>
            <div class="content-all-type">
                <div
                    class="guarante-item"
                    v-for="(item, index) in guaranteList"
                    :key="index"
                >
                    <div class="icon-label">
                        <img :src="item.img" />
                        <div class="label">{{ item.label }}</div>
                    </div>
                    <div class="description">{{ item.description }}</div>
                    <div class="chjg">出函机构：{{ item.chjg }}</div>
                    <div
                        class="apply-btn"
                        @click="applyGuaranteHandler(item)"
                    >申请保函</div>
                </div>
            </div>
        </div>

        <DialogBhType
            v-model="selectGuaranteeDialogStatus"
            isIndexApply
            :indexGuaranteItem="guaranteItme"
        ></DialogBhType>
    </div>
</template>
<script>
import { mapState } from 'vuex';
//选择保函
import DialogBhType from '@components/sys/dialog-bh-type.vue';

export default {
    name: 'applyGuarante',
    components: {
        DialogBhType,
    },
    data() {
        return {
            guaranteList: [
                {
                    img: '/images/homePage/apply-guarante/tbbh.png',
                    label: '投标保函',
                    description:
                        '给发包人或采购人提供的保证，保证投标人按招标要求进行投标的担保保函。',
                    chjg: '银行、担保公司、保险公司',
                    key: 'tbbh',
                },

                {
                    img: '/images/homePage/apply-guarante/lybh.png',
                    label: '履约保函',
                    description:
                        '给发包人或采购人提供的保证，保证承包人履行合同义务的担保保函。',
                    chjg: '银行、担保公司、保险公司',
                    key: 'lybh',
                },

                {
                    img: '/images/homePage/apply-guarante/yfkbh.png',
                    label: '预付款保函',
                    description:
                        '给发包人或采购人提供的保证，保证承包人收到预付款后在项目中如约履行的担保保函。',
                    chjg: '银行、担保公司、保险公司',
                    key: 'yfkbh',
                },

                {
                    img: '/images/homePage/apply-guarante/nmggzzfbh.png',
                    label: '农民工工资支付保函',
                    description:
                        '给发包人或采购人提供的保证，保证承包人收到预付款后在项目中如约履行的担保保函。',
                    chjg: '银行、担保公司、保险公司',
                    key: 'nmggzzfbh',
                },

                {
                    img: '/images/homePage/apply-guarante/zlbxbh.png',
                    label: '质量保修保函',
                    description:
                        '给发包人或采购人提供的保证，保证承包人对所承诺质量保证的项目进行维修保修的担保保函。',
                    chjg: '银行、担保公司、保险公司',
                    key: 'zlbxbh',
                },

                {
                    img: '/images/homePage/apply-guarante/yzzfbh.png',
                    label: '业主支付保函',
                    description:
                        '给承包人提供的保证，保证发包人如约支付项目进度款的担保保函。',
                    chjg: '担保公司',
                    key: 'yzzfbh',
                },
            ],

            selectGuaranteeDialogStatus: false,
            guaranteItme: {},
        };
    },
    computed: {
        ...mapState({
            bhlxList: (state) => state.dictionary.bhlx,
        }),
    },
    methods: {
        applyGuaranteHandler: function (item) {
            let needlist = this.bhlxList.map((item) => item.valueid);

            if (!needlist.includes(item.key)) {
                showMsg('暂未开通', 'error');
                return;
            }

            this.guaranteItme = item;
            this.selectGuaranteeDialogStatus = true;
        },
    },
    created() {},
    mounted() {},
};
</script>
<style lang="scss" scoped>
@import '@css/var.scss';
/* 申请保函 */
.apply-guarante {
    background-color: #fbf7f5;
    padding: 16px 0;

    .apply-content {
        width: 1200px;
        margin: 0px auto;
        display: flex;
    }

    .content-img {
        width: 324px;
        height: 637px;
        flex-shrink: 0;
        background: url('/images/homePage/apply-guarante/leftImg.png') no-repeat;
        padding: 24px;
        color: white;
        margin-right: 16px;

        .title {
            font-size: 29px;
            padding: 36px 0 60px;
        }
        .text {
            font-size: 22px;
        }
    }

    .content-all-type {
        display: flex;
        flex-wrap: wrap;

        .guarante-item {
            background-color: white;
            width: 275px;
            padding: 14px;
            position: relative;
            transition: all 0.5s;
            margin-top: 16px;
            margin-right: 16px;

            .icon-label {
                display: flex;
                align-items: center;
                height: 55px;

                img {
                    height: 35px;
                }

                .label {
                    padding-left: 10px;
                    font-size: 22px;
                    transition: all 0.3s;
                }
            }

            .description {
                font-size: 16px;
                height: 100px;
                overflow: hidden;
                color: #5f6468;
            }

            .chjg {
                font-size: 14px;
                transition: all 0.5s;
            }

            .apply-btn {
                position: absolute;
                bottom: 24px;
                left: 29px;
                right: 29px;
                height: 42px;
                background-color: $primaryColor;
                text-align: center;
                line-height: 42px;
                color: white;
                font-size: 16px;
                transition: all 0.3s;
                cursor: pointer;
            }

            .apply-btn:hover {
                opacity: 0.8;
            }
        }

        /* 兼容ie */
        .guarante-item:nth-child(-n + 3) {
            margin-top: 0;
        }

        .guarante-item:nth-child(3n) {
            margin-right: 0;
        }

        .guarante-item:hover {
            color: $primaryColor !important;
            box-shadow: 0px 0px 2px 1px $primaryColor;
        }

        .guarante-item:hover .chjg,
        .icon-label .label {
            color: $primaryColor !important;
        }
    }
}
</style>

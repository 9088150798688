<template>
    <div class="guarante-enquiry" id="enquiry">
        <div class="enquiry-content">
            <div class="guarante-type">
                <div 
                    v-for="(item, index) in bhlxList" 
                    :key="index"
                    :class="[item.valueid === form.bhlx ? 'type-btn isactivited' : 'type-btn']" 
                    @click="bhlxClick(item)"
                >
                    {{ item.valuename }}
                </div>
            </div>

            <div class="enquiry">
                <div class="title">
                    <span>一键询价</span>
                </div>
                <el-form :model="form" ref="enquiryFormRef" label-width="140px" class="enquiry-form">
                    <div style="padding:20px 50px 0 0">
                        <el-form-item 
                            prop="chjg" 
                            label="出函机构"
                            :rules="[{ required: true, message: '请选择出函机构', trigger: ['blur', 'change'] }]"
                        >
                            <el-radio-group v-model="form.chjg">
                                <el-radio 
                                    :label="item.valueid" 
                                    v-for="(item, index) in chjgList"
                                    :key="index"
                                >
                                    {{ item.valuename }}
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <div v-if="!isTbbh">
                            <el-form-item 
                                label="项目类别" 
                                prop="protype"
                                :rules="[{ required: true, message: '请选择项目类别', trigger: ['blur', 'change'] }]"
                            >
                                <el-select 
                                    v-model="form.protype" 
                                    placeholder="请选择项目类别"
                                    style="width:100%"
                                >
                                    <el-option 
                                        v-for="(item, index) in projettypeList" 
                                        :key="index" 
                                        :label="item.value"
                                        :value="item.key"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item 
                                label="担保期限" 
                                prop="dbqx"
                                :rules="[
                                    { required: true, message: '请输入担保期限', trigger: 'blur' }, 
                                    { validator: checkNumber, trigger: ['blur', 'change'] }
                                ]"
                            >
                                <el-input 
                                    v-model="form.dbqx" 
                                    placeholder="请输入担保期限"
                                >
                                    <span slot="suffix" class="suffix">月</span>
                                </el-input>
                            </el-form-item>
                        </div>

                        <el-form-item 
                            class="earnest" 
                            label="保证金额" 
                            prop="earnest"
                            :rules="[
                                { required: true, message: '请输入保证金额', trigger: 'blur' }, 
                                { validator: earnestSixCheck, trigger: ['blur', 'change'] }
                            ]"
                        >
                            <el-input 
                                v-model="form.earnest" 
                                placeholder="请输入保证金额"
                            >
                                <span slot="suffix" class="suffix">万元</span>
                            </el-input>
                        </el-form-item>

                        <el-form-item 
                            style="margin:0" 
                            label="费用结算" 
                            prop=""
                        >
                            <span class="money" v-loading="loading">￥ {{ price }} 元</span>
                        </el-form-item>

                        <div class="tips">注：实际支付金额以支付页面显示为准!</div>
                    </div>
                </el-form>

                <div class="enquiry-btn">
                    <div class="btn" @click=enquiryClick>立即询价</div>
                </div>
            </div>

            <div class="enquiry-img">
                <img :src="enquiryImgSrc"/>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';

export default {
    name: 'guaranteEnquiry',
    components: {
    },
    props: {
        isLogin:{
            type:Boolean,
            default:false,
        },
    },
    data () {
        return {

            loading:false,
            enquiryImgSrc: '/images/homePage/enquiry/enquiry.png',

            form:{
                vtype: 'bht',
                bhlx:'tbbh'
            },

            price:0,

            projettypeList:[
                {value:'工程类',key:'gc'},
                {value:'采购类',key:'cg'},
            ],        }
    },
    computed: {
        ...mapState({
            bhlxList: state => state.dictionary.bhlx,
            chjgList: state => state.dictionary.chjg,
        }),
        isTbbh:function(){
            if(!this.form.bhlx) return true
            return this.form.bhlx === 'tbbh' ? true : false
        },
    },
    watch: {
        isTbbh:{
            handler:function(n,o){
                if(n === false){
                    this.$set(this.form , 'protype' , undefined);
                    this.$set(this.form , 'dbqx' , undefined);
                }
            },
            immediate:true,
        }
    },
    methods: {
        checkNumber: function (rule, value, callback) {
            if (!(/^[0-9]*$/.test(value))) {
                callback(new Error('只允许输入整数'));
            } else {
                callback()
            }
        },

        earnestSixCheck: function (rule, value, callback) {
            if (/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,6})?$/.test(value)) {
                if(value == 0){
                    callback(new Error('担保金额不能为零'));
                }else{
                    callback();
                }
            } else {
                callback(new Error('担保金额格式不正确'));
            }
        },

        bhlxClick:function(val){
            this.price = 0;
            if(this.form.bhlx === val.valueid){
                this.$set(this.form , 'bhlx' , undefined);
                return
            }
            this.$set(this.form , 'bhlx' , val.valueid);
        },

        enquiryClick:function(){
            let that = this;
            
            if(!this.isLogin){

                ShowMsg('请登录！', 'error');

                if(!!window.ActiveXObject || "ActiveXObject" in window){
                    document.documentElement.scrollTop = 0;
                }else{
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }

                return
            }

            this.$refs.enquiryFormRef.validate((valid) => {
                if (valid) {
                    if(!that.form.bhlx) return ShowMsg('请选择保函类型后继续操作', 'error');

                    if(that.form.bhlx === 'tbbh'){
                        that.$set(that.form , 'protype' , undefined);
                        that.$set(that.form , 'dbqx' , undefined);
                    }
                    
                    that.loading = true;

                    that.$post(`${that.$store.state.api.baseUrl}/webinfo/computeprice.json`, that.form ,function(data){
                        that.price = data;
                        that.loading = false;
                    })

                } else {
                    return false;
                }
            });
        }
    },
    created () {

    },
    mounted () {
        this.$store.dispatch('dictionary/getBhDic');
    },
}
</script>
<style lang="scss" scoped>
    @import '@css/var.scss';
    /* 保函询价 */
    .guarante-enquiry{
        padding-top: 70px;
        display: flex;
        align-items: center;
        justify-content: center;

        .enquiry-content{
            width: 1200px;
            display: flex;
            box-shadow: 0 2px 15px 0 rgba(0,0,0,.3);

        }

        .guarante-type{
            flex-shrink: 0;
            background-color: #fff3ed;
            padding: 22px 12px;
            
            .type-btn{
                width: 235px;
                height: 70px;
                font-size: 19px;
                color: black;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;
                box-shadow: 0 0px 5px 0  rgba(255,85,0,0.2);
                margin-bottom: 16px;
                border-radius: 5px;
                cursor: pointer;
                transition: all 0.5s;
            }

            .type-btn:nth-last-child(1){
                margin-bottom: 0px !important;
            }
            .type-btn:hover{
                box-shadow: 0 0px 5px 0 $primaryColor;
            }

            .isactivited{
                background-color: #ffdfcf;
                color:$primaryColor;
            }

        }

        .enquiry{
            flex: 1;
            background-color: white;
            padding: 30px 0;
            position: relative;
        
            .title{ 
                text-align: center;
                height: 60px;
            
                span{ 
                    font-size: 26px;
                    position: relative;
                }
            
                span:after {
                    content: '';
                    position: absolute;
                    bottom: -20px;
                    left: 30px;
                    right: 30px;
                    height: 3px;
                    background-color: $primaryColor;
                }
            }
        }



        .enquiry-form {
            .suffix{
                margin: 0 10px;
                color: $primaryColor;
                font-size: 16px;
            }

            .money{
                color: $primaryColor;
                font-size: 24px;
            }
            
            .tips{
                padding-top: 5px;
                padding-left: 60px;
                font-size: 16px;
            }
        }

        .enquiry-btn{
            position: absolute; 
            left: 0;
            right: 0;
            bottom: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        
            .btn{
                background-color: $primaryColor;
                height: 50px;
                width: 220px;
                line-height: 50px;
                text-align: center;
                color: white;
                font-size: 16px;
                cursor: pointer;
                transition: all 0.3s;
                letter-spacing:5px;
            }
        
            .btn:hover{
                opacity: 0.8;
            }
            
        }

        .enquiry-img{
            width:398px ;
            height: 548px;
            img{
                height: 100%;
                width: 100%;
            }
        }
    }


    ::v-deep .enquiry-form .el-form-item__label{
        font-size: 17px;
        color: black;
    }

    ::v-deep .enquiry-form  .el-radio__label{
        font-size: 16px;
        padding-left:6px;
    }
    
    ::v-deep .enquiry-form  .el-radio__inner{
        width: 16px;
        height: 16px;
    }

    ::v-deep .enquiry-form  .el-input--suffix .el-input__inner{
        padding-right: 55px;
    }
    
</style>

<template>
    <Layout>
        <div class="banner-login">

            <top-carousel :banner-list="topBannerList"></top-carousel>

            <div class="login-parent">
                <LoginInfoCard></LoginInfoCard>
            </div>

        </div>

        <handling-flow></handling-flow>

        <apply-guarante></apply-guarante>

        <article class="article-more">
            <platform-advantage></platform-advantage>

            <guarante-enquiry :is-login="loginStatus"></guarante-enquiry>

            <bottom-carousel></bottom-carousel>
        </article>

        <partners></partners>
    </Layout>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import PAGE_MIXIN from '@mixins/page.js';

import Layout from '@/web/components/layout/index.vue';

//顶部banner
import topCarousel from './sections/topCarousel';
import LoginInfoCard from './sections/login-info-card.vue';

//办理流程
import handlingFlow from './sections/handlingFlow';
//申请保函
import applyGuarante from './sections/applyGuarante';
//平台优势
import platformAdvantage from './sections/platformAdvantage';
//一键询价
import guaranteEnquiry from './sections/guaranteEnquiry';
//底部banner
import bottomCarousel from './sections/bottomCarousel';
//合作伙伴
import partners from './sections/partners';

export default {
    mixins: [PAGE_MIXIN],
    components: {
        Layout,
        topCarousel,
        LoginInfoCard,

        handlingFlow,
        applyGuarante,
        platformAdvantage,
        guaranteEnquiry,
        bottomCarousel,
        partners,
    },

    data() {
        return {
            topBannerList: [],

            loginType: 'password',

            loginTypeList: [
                {
                    label: '密码',
                    key: 'password',
                    lineShow: true,
                },
                {
                    label: '手机号',
                    key: 'phone',
                    lineShow: true,
                },
                {
                    label: '微信扫码',
                    key: 'wx',
                    lineShow: false,
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            getUserinfo: 'userinfo/getUserinfo',
            loginStatus: 'userinfo/loginStatus',
        }),
    },
    watch: {},
    methods: {

        getTopBannerList() {
            this.$get(
                `${this.$store.state.api.baseUrl}/webinfo/adlist`,
                {
                    adtype: '1',
                },
                (data) => {
                    this.topBannerList = data;
                }
            );
        },
    },
    created() {},
    mounted() {
        this.getTopBannerList();

        this.$store.dispatch('dictionary/getBhDic');
    },
};
</script>
<style lang="scss" scoped>
@import '@css/var.scss';

.article-more {
    background: url('/images/homePage/advantage/advantage-bg.png') no-repeat;
    padding-bottom: 70px;
}

.banner-login {
    position: relative;

    .login-parent {
        width: 1200px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;

        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

.bht_active {
    color: $primaryColor;
}
</style>

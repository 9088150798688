<template>
    <div class="handing-flow">
        <handing-title>
            <span slot="mainTile">保函办理流程</span>
            <span slot="smallTile">全流程在线办理 三分钟办理 极速出函</span>
        </handing-title>
        <div class="content">
            <div class="flow-item" v-for="(item, index) in flowList" :key="index">
                <div class="left-icon">
                    <img :src="item.imgIcon"/>
                    <div>{{ item.title }}</div>
                </div>
                <div class="right-line" v-if="!!item.line">
                    <img :src="item.line"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import handingTitle from '../components/handingTitle'

export default {
    name: 'handlingFlow',
    components: {
        handingTitle,
    },
    data () {
        return {

            flowList: [
                {
                    imgIcon: '/images/homePage/flow/flow-1.png',
                    title: '填写信息',
                    line: '/images/homePage/flow/flow-line.png',
                },
                {
                    imgIcon: '/images/homePage/flow/flow-2.png',
                    title: '支付',
                    line: '/images/homePage/flow/flow-line.png',
                },
                {
                    imgIcon: '/images/homePage/flow/flow-3.png',
                    title: '审核',
                    line: '/images/homePage/flow/flow-line.png',
                },
                {
                    imgIcon: '/images/homePage/flow/flow-4.png',
                    title: '出函',
                },
            ]
        }
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
    @import '@css/var.scss';
    /* 办理流程 */
    .handing-flow {
        padding: 70px 0;

        .content {
            padding-top: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        .flow-item {
            display: flex;
            align-items: center;
        
            .left-icon {
                text-align: center;
                font-size: 16px;
            }
            
            .right-line {
                padding: 0 36px;
            }
        }
    }


</style>

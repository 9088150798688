<template>
    <div class="platform-advantage">
        <handing-title>
            <span slot="mainTile">平台优势</span>
            <span slot="smallTile">推动电子保函业务全国普及及实施</span>
        </handing-title>
        <div class="content-all">
            <div class="advantage-item" v-for="(item, index) in advantageList" :key="index">
                <img :src="item.imgIcon"/>
                <div class="label">{{ item.label }}</div>
                <div class="description">{{ item.description }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import handingTitle from '../components/handingTitle'
export default {
    name: 'platformAdvantage',
    components: {
        handingTitle,
    },
    data () {
        return {
            advantageList:[
                {
                    imgIcon:'/images/homePage/advantage/advantage-1.png',
                    label:'响应政策',
                    description:'响应国家政策，优化营商环境，降低企业成本。',
                },
                {
                    imgIcon:'/images/homePage/advantage/advantage-2.png',
                    label:'提高效率',
                    description:'全流程在线办理，三分钟办理，极速出函，投标人可实时查询电子保函的办理进度以及保函的状态。',
                },
                {
                    imgIcon:'/images/homePage/advantage/advantage-3.png',
                    label:'技术支持',
                    description:'通过应用区块链、API等数字技术，为金融平台提供数字化、智能化服务，赋能各有需求的平台，以满足企业多方面需求。',
                },
                {
                    imgIcon:'/images/homePage/advantage/advantage-4.png',
                    label:'服务体系',
                    description:'提供完善的售后服务、咨询服务、增值服务、配置服务、其他特色服务等。',
                },
            ]
        }
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
@import '@css/var.scss';
/* 平台优势 */
.platform-advantage{
    padding-top: 70px;

    .content-all{
        padding-top: 40px;
        width: 1200px;
        margin: 0px auto;
        display: flex;
    
    }

    .advantage-item{
        flex: 1;
        flex-shrink: 0;
        padding:14px;
        height: 260px;
        background-color: white;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.3);
        border-top-right-radius: 50px;
        transition: all 0.3s;
        margin-right: 40px;

        img{
            height: 60px;
            width: 60px;
        }

        .label{
            font-size: 22px;
        }

        .description{
            padding-top: 8px;
            font-size: 16px;
            color: #5f6468;
        }
    }

    /* 兼容IE */
    .advantage-item:nth-last-child(1){
        margin-right: 0px;
    }
    
    .advantage-item:hover{
        box-shadow: 0px 0px 2px 1px $primaryColor;
    }
}

</style>

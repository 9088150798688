<template>
    <div class="bottom-carousel">
        <el-carousel :interval="3000" arrow="never" :height="carouselHeight">
            <el-carousel-item v-for="(item, index) in imgList" :key="index">
                <img :src="item.imgUrl" alt=""/>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>
export default {
    name: 'bottomCarousel',
    components: {
    },
    props: {
        carouselHeight: {
            type: String,
            default: '231px'
        }
    },
    data () {
        return {
            imgList:[
                {
                    imgUrl:'/images/homePage/carousel/carousel-1.png',
                },
            ]
        }
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
    @import '@css/var.scss';
    /* 走马灯 */
    .bottom-carousel{
        padding-top: 50px;
        width: 1200px;
        overflow: hidden;
        margin: 0 auto;

        .el-carousel{
            width: 100%;
            height: 100%;
            
            img{
                width: 100%;
                height: 100%;
            }
        }
    }

    ::v-deep .el-carousel__indicators{
        position: absolute !important;
        transform: unset;
        left: 20px;
    }

    ::v-deep .el-carousel__indicators .el-carousel__button { 
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
        opacity: 1;
    }

    ::v-deep .el-carousel__indicators .is-active .el-carousel__button { 
        background: #ff5500 !important;
        opacity: 1;
    } 

</style>


<template>
    <div class="partners-flex">
        <div
            v-for="(item,index) in imgList"
            :key="index"
            class="item"
        >
            <img
                :src="item.imgUrl"
                :alt="item.imgUrl"
            />
        </div>
    </div>
</template>
<script>
export default {
    name: 'partnersFlex',
    components: {},
    props: {
        imgList: {
            type: Array,
            default: function () {
                [];
            },
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    methods: {},
    created() {},
    mounted() {},
};
</script>
<style lang="scss" scoped>
.partners-flex {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;

    .item {
        display: block;
        flex: 1;
    }

    img {
        display: block;
        width: 100%;
    }
}
</style>

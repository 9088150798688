<template>
    <div class="partners">
        <div class="partners-content">
            <handing-title :title-left="lineLeft" :title-right="lineRight">
                <span slot="mainTile" style="color:white">合作金融机构</span>
            </handing-title>
            <div class="all-imgs">
                <partners-flex :img-list="partnerList.slice(0, 6)"></partners-flex>
                <partners-flex :img-list="partnerList.slice(6)"></partners-flex>
            </div>
        </div>
    </div>
</template>
<script>

import handingTitle from '../components/handingTitle'
import partnersFlex from '../components/partnersFlex'

export default {
    name: 'partners',
    components: {
        handingTitle,
        partnersFlex,
    },
    data () {
        return {
            lineLeft: '/images/homePage/partners/left.png',
            lineRight: '/images/homePage/partners/right.png',

            partnerList: [
                {
                    imgUrl: '/images/homePage/partners/1-中国建设银行.png',
                    sort: 1,
                },
                {
                    imgUrl: '/images/homePage/partners/2-中信银行.png',
                    sort: 2,
                },
                {
                    imgUrl: '/images/homePage/partners/3-河北银行.png',
                    sort: 3,
                },
                {
                    imgUrl: '/images/homePage/partners/4-中国光大银行.png',
                    sort: 4,
                },
                {
                    imgUrl: '/images/homePage/partners/重庆富民银行.png',
                    sort: 5,
                },
                {
                    imgUrl: '/images/homePage/partners/黄河财险.png',
                    sort: 6,
                },

                {
                    imgUrl: '/images/homePage/partners/4-中华保险.png',
                    sort: 7,
                },
                {
                    imgUrl: '/images/homePage/partners/5-阳光保险.png',
                    sort: 8,
                },
                {
                    imgUrl: '/images/homePage/partners/6-新合作.png',
                    sort: 9,
                },
                {
                    imgUrl: '/images/homePage/partners/7-瀚华担保.png',
                    sort: 10,
                },
                {
                    imgUrl: '/images/homePage/partners/8-众城.png',
                    sort: 11,
                },
                {
                    imgUrl: '/images/homePage/partners/天恒融资担保.png',
                    sort: 12,
                },
            ]
        }
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
    /* 合作机构 */
    .partners{
        display: flex;
        align-items: center;
        justify-content: center;
        background: url('/images/homePage/partners/background.png') no-repeat;
        background-size:cover;
        padding: 70px 0 50px;

        .partners-content{
            width: 1200px;
            overflow: hidden;
            .all-imgs{
                padding-top: 70px;
            }
        }
    }


</style>

<template>
    <div>
        <el-carousel
            :interval="5000"
            arrow="always"
            :height="carouselHeight"
        >
            <el-carousel-item
                v-for="(item,index) in bannerList"
                :key="index"
            >
                <img
                    :src="item.IMGURL"
                    alt=""
                    @click="applynow"
                />
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>
export default {
    name: 'topCarousel',
    components: {},
    props: {
        bannerList: {
            type: Array,
            default: function () {
                return [];
            },
        },

        carouselHeight: {
            type: String,
            default: '460px',
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    methods: {
        applynow: function () {},
    },
    created() {},
    mounted() {},
};
</script>
<style lang="scss" scoped>
.el-carousel {
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}
</style>

<template>
    <div class="handing-title">
        <div class="handing-main-title">
            <img :src="titleLeft"/>
            <span class="maintitle">
                <slot name="mainTile"></slot>
            </span>
            <img :src="titleRight"/>
        </div>
        <div class="handing-small-title">
            <slot name="smallTile"></slot>
        </div>
     </div>
</template>
<script>
export default {
    name: 'handingTitle',
    components: {
    },
    props: {
        titleLeft:{
            type:String,
            default:'/images/homePage/title/title-left.png',
        },
        titleRight:{
            type:String,
            default:'/images/homePage/title/title-right.png',
        },
    },
    data () {
        return {
        }
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
    @import '@css/var.scss';
    .handing-title {
        text-align: center;

        .maintitle{
            padding: 0 20px;
            font-size: 29px;
        }
        
        .handing-small-title{
            font-size: 19px;
            color: #5f6468;
        }
    }
</style>

